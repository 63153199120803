import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get() {
    let result = await axios.get(`${API_ROUTES.enterprises.get}`);

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.enterprises.save}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.enterprises.delete}/${id}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(
      `${API_ROUTES.enterprises.update}/${id}`,
      data
    );

    return result;
  },

  async getDocuments(clientId) {
    let result = await axios.get(
      `${API_ROUTES.enterpriseDocuments.get}/${clientId}`
    );

    return result;
  },

  async uploadDocument(data) {
    let result = await axios.post(
      `${API_ROUTES.enterpriseDocuments.save}`,
      data
    );

    return result;
  },

  async deleteDocument(id) {
    let result = await axios.delete(
      `${API_ROUTES.enterpriseDocuments.delete}/${id}`
    );

    return result;
  }
};
